<script>
import penaltyApi from '@/api/penalty'
import receipt from "@/views/penalties/mixins/receipt";
import penaltyPayment from "@/components/app/penalties/mixin/penaltyPayment";
import displaySettings from "@/mixin/displaySettings";

export default {
    name: "PenaltyPage",
    mixins: [receipt, penaltyPayment, displaySettings],
    data() {
        return {
            penalty: null,
            checkedOffer: false,
            payment: null,
        }
    },
    watch: {
        '$route.params.docId': function (val) {
            this.getPenalty();
        }
    },
    methods: {
        checkPaid() {
            if (this.penalty.payment && this.penalty.payment.status === 'pending') {
                setTimeout(() => {
                    this.getPenalty();
                }, 1000);
            }
        },
        getPenalty() {
            penaltyApi.getPenaltyForClient(this.$route.params.docId).then(response => {
                this.penalty = response.data
                this.checkPaid();
            }).catch(error => {
                if (error.response.status === 404) {
                    this.$router.push({name: 'prime'})
                }
            })
        },
    },
    mounted() {
        this.getPenalty();
    }
}
</script>

<template>
    <div :class="['penalty', !isMobile ? 'pr-5 pl-5 mr-5 ml-5': 'pr-2 pl-2']">
        <div v-if="penalty">
            <el-row v-if="penalty.client">
                <el-col>
                    <h1>Шановний(-а), {{ penalty.client.fullName }}, вам надіслано інформацію про штраф
                        {{ penalty.protocol }} </h1>
                </el-col>
            </el-row>
            <el-row type="flex" justify="middle">
                <el-col>
                    <div class="d-flex align-items-center">
                        <div class="protocol">
                            {{ penalty.protocol }}
                        </div>
                        <div class="status paid ml-2 pl-1 pr-1" v-if="penalty.dpaid">
                                                <span>
                                                    Сплачено
                                                </span>
                        </div>
                        <div class="status wait-confirm ml-2 pl-1 pr-1"
                             v-else-if="penalty.status === 'ОЧІКУЄТЬСЯ ПІДТВЕРДЖЕННЯ ОПЛАТИ З ДЕРЖАВНОЇ КАЗНАЧЕЙСЬКОЇ СЛУЖБИ'">
                                                <span>
                                                    {{ penalty.status }}
                                                </span>
                        </div>
                        <div class="status privilege ml-2 pl-1 pr-1"
                             v-else-if="penalty.isDiscount">
                                                <span>
                                                    Пільга на сплату
                                                    <i class="icon exclamation-circle"></i>
                                                </span>
                        </div>
                        <div class="status ml-2 pl-1 pr-1" v-else>
                                                <span>
                                                    Пільга відсутня
                                                    <i class="icon exclamation-circle"></i>
                                                </span>
                        </div>
                    </div>
                </el-col>
                <el-col>

                </el-col>
            </el-row>
            <el-row type="flex" align="middle" class="head">
                <el-col class="text-center p-2">Загальна інформація</el-col>
            </el-row>
            <el-row type="flex">
                <el-col>
                    <div class="mb-2">
                        <b>Постанова</b>
                    </div>
                    <div>
                        {{ penalty.protocol }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Дата постанови</b>
                    </div>
                    <div>{{ penalty.dsignPost }}</div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Сума штрафу</b>
                    </div>
                    <div>
                        {{ penalty.sumPenalty }}
                    </div>
                </el-col>
            </el-row>
            <el-row class="head" v-if="penalty.client">
                <el-col class="text-center p-2">Клієнт</el-col>
            </el-row>
            <el-row type="flex" v-if="penalty.client">
                <el-col>
                    <div class="mb-2">
                        <b>ПІБ</b>
                    </div>
                    <div>
                        {{ penalty.client.fullName }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Телефон</b>
                    </div>
                    <div>
                        {{ penalty.client.phone }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Email</b>
                    </div>
                    <div>
                        {{ penalty.client.email }}
                    </div>
                </el-col>
            </el-row>
            <el-row type="flex" v-if="penalty.client">
                <el-col>
                    <div class="mb-2">
                        <b>Документ, що засвідчує особу</b>
                    </div>
                    <div>
                        {{ penalty.client.document.type }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Серія</b>
                    </div>
                    <div>
                        {{ penalty.client.document.serial }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Номер</b>
                    </div>
                    <div>
                        {{ penalty.client.document.number }}
                    </div>
                </el-col>
            </el-row>
            <el-row class="head">
                <el-col class="text-center p-2">
                    Транспортний засіб
                </el-col>
            </el-row>
            <el-row type="flex">
                <el-col>
                    <div class="mb-2">
                        <b>Номер авто</b>
                    </div>
                    <div>
                        {{ penalty.car.licensePlate }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Марка</b>
                    </div>
                    <div>
                        {{ penalty.car.brand }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Модель</b>
                    </div>
                    <div>{{ penalty.car.mark }}</div>
                </el-col>
            </el-row>
            <el-row class="head">
                <el-col class="text-center p-2">
                    Деталі правопорушення
                </el-col>
            </el-row>
            <el-row type="flex">
                <el-col>
                    <div class="mb-2">
                        <b>Місце вчинення</b>
                    </div>
                    <div>
                        {{ penalty.region }},
                        {{ penalty.district }},
                        {{ penalty.city }},
                        {{ penalty.street }}
                    </div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Дата правопорушення</b>
                    </div>
                    <div>{{ penalty.dperpetration }}</div>
                </el-col>
                <el-col>
                    <div class="mb-2">
                        <b>Стаття</b>
                    </div>
                    <div>
                        {{ penalty.pdd }}
                    </div>
                </el-col>
            </el-row>
            <el-row class="head">
                <el-col class="text-center p-2">
                    Платежі
                </el-col>
            </el-row>
            <el-row type="flex" class="payment">
                <el-col
                    v-if="!penalty.payment && penalty.status === 'ОЧІКУЄТЬСЯ ПІДТВЕРДЖЕННЯ ОПЛАТИ З ДЕРЖАВНОЇ КАЗНАЧЕЙСЬКОЇ СЛУЖБИ'">
                    <div class="status pl-1 pr-1">
                        Сплачено іншою системою
                    </div>
                </el-col>
                <el-col v-else :span="6" :xs="24">
                    <el-row type="flex" align="middle" class="mb-0">
                        <el-col>
                            <div>
                                <b>Cума до сплати:</b>
                            </div>
                        </el-col>
<!--                        <el-col>-->
<!--                            <div class="sum-value">-->
<!--                                {{ parseFloat(penalty.sumPenalty) + parseFloat(penalty.sumPenalty) * 0.1 }} грн-->
<!--                            </div>-->
<!--                        </el-col>-->
                        <el-col>
                            <div class="sum-value">
                                {{ parseFloat(penalty.sumPenalty) }} грн
                            </div>
                        </el-col>
                    </el-row>
                    <el-row type="flex" align="middle" class="mt-0">
                        <el-col>
                            <div class="d-flex">
                                <div v-if="penalty.payment && penalty.payment.status === 'success'"
                                     class="status paid pl-1 pr-1">
                                    Успішна оплата
                                </div>
                                <div
                                    v-else-if="!penalty.payment && penalty.status === 'ОЧІКУЄТЬСЯ ПІДТВЕРДЖЕННЯ ОПЛАТИ З ДЕРЖАВНОЇ КАЗНАЧЕЙСЬКОЇ СЛУЖБИ'"
                                    class="status pl-1 pr-1">
                                    Сплачено іншою системою
                                </div>
                                <div v-else class="status pl-1 pr-1">
                                    Не сплачено
                                </div>
                            </div>
                        </el-col>
                        <el-col></el-col>
                    </el-row>
<!--                    <el-row type="flex" align="middle">-->
<!--                        <el-col>-->
<!--                            <b>Сума штрафу:</b>-->
<!--                        </el-col>-->
<!--                        <el-col>-->
<!--                            <span class="sum-penalty">-->
<!--                                {{ penalty.sumPenalty }} грн-->
<!--                            </span>-->
<!--                        </el-col>-->
<!--                    </el-row>-->
<!--                    <el-row type="flex" align="middle">-->
<!--                        <el-col>-->
<!--                            <div class="commission-label">-->
<!--                                Загальна комісія сервісу:-->
<!--                            </div>-->
<!--                        </el-col>-->
<!--                        <el-col>-->
<!--                            <span class="commission-value">-->
<!--                                {{ parseFloat(penalty.sumPenalty) * 0.1 }} грн-->
<!--                            </span>-->
<!--                        </el-col>-->
<!--                    </el-row>-->
                    <el-row>
                        <el-col>
                            <div class="checked-offer d-flex" v-if="!penalty.payment || penalty.payment.status !== 'success' || penalty.payment.status === 'pending'">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        name="agreement"
                                        id="agreement"
                                        class="form-check-input"
                                        v-model="checkedOffer"
                                    />
                                    <label for="agreement" class="form-check-label  pr-2"/>
                                </div>
<!--                                <el-checkbox v-model="checkedOffer" size="medium" class="pr-2"></el-checkbox>-->
                                <span>Погоджуюсь з <a href="/pdf/fines-contract.pdf" target="_blank">умовами оферти</a> надання інформації про штраф</span>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row>
<!--                        v-if="!penalty.payment || penalty.status === 'ОЧІКУЄТЬСЯ ПІДТВЕРДЖЕННЯ ОПЛАТИ З ДЕРЖАВНОЇ КАЗНАЧЕЙСЬКОЇ СЛУЖБИ'"-->
                        <el-col :span="20" :xs="24">
                            <el-button v-if="penalty.payment && penalty.payment.status === 'success'"
                                       type="primary"
                                       class="w-100"
                                       @click="getReceipt(penalty.id)"
                            >
                                Завантажити квитанцію
                            </el-button>
                            <el-button
                                v-else
                                :disabled="!checkedOffer"
                                type="primary"
                                class="w-100"
                                @click="payPenalty(penalty)"
                            >Сплатити
                            </el-button>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<style scoped>
.penalty {
    [type=button]:not(:disabled).el-button--primary {
            background-color: #007BBB;
            border-color: #007BBB;
        }
    .checked-offer {
        .el-checkbox__inner::after {
            height: 12px;
            left: 7px;
        }

        .el-checkbox__inner {
            ::after {
                height: 12px;
                left: 7px;
            }

            width: 20px;
            height: 20px;
        }
        label:before {
            width: 20px;
            height: 20px;
        }

        span {
            font-size: 15px;
        }
    }

    .protocol {
        font-size: 25px;
        font-weight: 700;
        line-height: 36.85px;
    }

    .el-row {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .head {
        background: #F6F7FB;
        font-weight: 700;
        font-size: 25px;
        border-radius: 3px;
    }

    .status {
        border-radius: 30px;
        height: 19px;
        color: #ffffff;
        font-size: 14px;
        background: #34393D;
        text-align: center;
    }

    .privilege {
        background: #FFAD3D !important;
    }

    .wait-confirm {
        background: #007BBB !important;
    }

    .paid {
        background: #02A94E !important;
    }

    .payment {
        .el-row {
            margin-bottom: 19px;
        }

        .sum-penalty {
            color: #24282C;
            font-size: 30px;
            font-weight: 700;
            line-height: 44.22px;
        }

        .earnings-label {
            color: #999DA6;
            font-size: 15px;
            font-weight: 700;
        }

        .earnings {
            color: #50A64D;
            font-size: 20px;
            font-weight: 700;
        }

        .commission-label {
            color: #999DA6;
            font-size: 15px;
            font-weight: 700;
        }

        .commission-value {
            color: #24282C;
            font-size: 20px;
            font-weight: 700;
        }

        .sum-value {
            color: #FA9B13;
            font-size: 30px;
            font-weight: 700;
            line-height: 44.22px;
        }
    }
}
</style>
